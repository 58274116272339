@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?bl9im0');
  src:  url('fonts/icomoon.eot?bl9im0#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?bl9im0') format('truetype'),
    url('fonts/icomoon.woff?bl9im0') format('woff'),
    url('fonts/icomoon.svg?bl9im0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-umbrella-logo:before {
content: "\e931";
}
.icon-lightning-logo:before {
content: "\e92f";
}
.icon-airplane-globe:before {
content: "\e92e";
}
.icon-twitter:before {
content: "\e923";
}
.icon-linkedin:before {
content: "\e925";
}
.icon-take-flight:before {
content: "\e901";
}
.icon-paint:before {
content: "\e902";
}
.icon-email:before {
content: "\e903";
}
.icon-minus:before {
content: "\e904";
}
.icon-plus:before {
content: "\e905";
}
.icon-sms:before {
content: "\e906";
}
.icon-expand:before {
content: "\e907";
}
.icon-agent-contact:before {
content: "\e908";
}
.icon-business-intelligence:before {
content: "\e909";
}
.icon-cc-guidelines:before {
content: "\e90a";
}
.icon-check-in:before {
content: "\e90b";
}
.icon-dropdown-arrow:before {
content: "\e90c";
}
.icon-duty-of-care:before {
content: "\e90d";
}
.icon-fare-forecaster:before {
content: "\e90e";
}
.icon-hide-app:before {
content: "\e90f";
}
.icon-show-app:before {
content: "\e910";
}
.icon-hotel-directory:before {
content: "\e911";
}
.icon-invoices:before {
content: "\e912";
}
.icon-minimize-arrows:before {
content: "\e913";
}
.icon-my-bookings:before {
content: "\e914";
}
.icon-settings:before {
content: "\e915";
}
.icon-supplier-agreement:before {
content: "\e916";
}
.icon-traveler-tracker:before {
content: "\e917";
}
.icon-travel-policy:before {
content: "\e918";
}
.icon-buy-now-graph:before {
content: "\e919";
}
.icon-checkmark:before {
content: "\e91a";
}
.icon-close:before {
content: "\e91b";
}
.icon-filter:before {
content: "\e91c";
}
.icon-hold-graph:before {
content: "\e91d";
}
.icon-info:before {
content: "\e91e";
}
.icon-trash-delete:before {
content: "\e91f";
}
.icon-add:before {
content: "\e920";
}
.icon-arrow-dropdown:before {
content: "\e921";
}
.icon-accommodation:before {
content: "\e922";
}
.icon-car:before {
content: "\e924";
}
.icon-date:before {
content: "\e926";
}
.icon-location:before {
content: "\e927";
}
.icon-move-drag:before {
content: "\e928";
}
.icon-notification-bubble:before {
content: "\e929";
}
.icon-plane:before {
content: "\e92a";
}
.icon-search:before {
content: "\e92b";
}
.icon-set-as-default:before {
content: "\e92c";
}
.icon-switch:before {
content: "\e92d";
}
.icon-time:before {
content: "\e930";
}
.icon-arrow-dropdown-circle:before {
content: "\e934";
}
.icon-get-there:before {
  content: "\e900";
}
.icon-password-reset:before {
  content: "\e935";
}
