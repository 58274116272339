.DateRangePickerInput_calendarIcon {
  float: right;
  margin-left: 0;
  position: absolute;
  right: 2px;
  top: 3px;
}

.DateInput {
  width: 100%;
  padding-right: 0;
}

.DateRangePickerInput > .DateInput {
  width: 105px;
}

.DateInput_input {
  font-size: 15px;
  text-align: center;
  cursor: initial;
  line-height: 21px;
}

.DateRangePickerInput_arrow_svg {
  height: 44px;
}

.DateRangePickerInput {
  border-radius: 4px;
  overflow: hidden;
}

.DateInput_1,
.DateInput_input_1,
.SingleDatePickerInput_1 {
  border-radius: 4px;
}

.CalendarMonth_table {
  margin-top: 10px;
}

.DayPickerNavigation_button,
.DayPickerNavigation_button:hover {
  border: none;
}

.CalendarDay {
  border-color: transparent;
}

.CalendarDay__selected_span {
  background: #a1c9f4;
  color: white;
  border: 1px solid #a1c9f4;
}

.CalendarDay__selected {
  background: #007aff;
  border-color: #007aff;
  color: white;
}

.CalendarDay__selected:hover {
  background: #007aff;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__selected_span:hover,
.CalendarDay__hovered_span {
  background: #007aff;
  border-color: #007aff;
}

.DateInput_input__disabled {
  background: #F3F6FC;
  color: rgba(0, 0, 0, 0.38)
}

.DateInput_input__focused {
  border-bottom-color: #007aff;
}

.CalendarMonth_caption {
  color: #007aff;
}

.SingleDatePickerInput {
  border-color: rgba(0, 0, 0, 0.26);
}
.SingleDatePicker, .SingleDatePickerInput {
  width: 100%;
}